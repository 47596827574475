import React, { useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { Translated } from '../UI/Core';

interface InfoModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  onDeny: (id: string, reason: string) => void;
  id: string;
}

const DenyAccessProfileModal: React.FC<InfoModalProps> = ({ isModalOpen, onClose, onDeny, id }) => {
  // Intl
  const intl = useIntl();
  const [denialReason, setDenialReason] = useState('');
  const [form] = Form.useForm(); // Ant Design form instance
  const handleOkClick = () => {
    if (!denialReason) {
      form.setFields([
        {
          name: 'denialReason',
          errors: ['Denial reason is required'],
        },
      ]);
    } else {
      onDeny(id, denialReason);
      onClose();
      setDenialReason('');
    }
  };

  return (
    <Modal
      title={<Translated id="access.profile.deny.reason.modal.title" />}
      centered
      open={isModalOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          <Translated id="form.cancelButton" />
        </Button>,
        <Button key="ok" type="primary" onClick={handleOkClick}>
          <Translated id="app.ok" />
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="denyModalForm">
        <Form.Item
          name="denialReason"
          label="Reason"
          rules={[
            { required: true, message: intl.formatMessage({ id: 'accessProfileVerification.deny.reason.warning' }) },
          ]}
        >
          <Input
            value={denialReason}
            onChange={(e) => setDenialReason(e.target.value)}
            placeholder={intl.formatMessage({ id: 'access.profile.deny.reason.placeholder' })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DenyAccessProfileModal;
