export interface AccessProfile {
  Id: string;
  Name: string;
  Enabled: boolean;
  ValidFrom: string;
  ValidTo: string;
  RequiresVerification: string;
}

export interface AccessProfileAuthorizedUser {
  DenyReason: string;
  RequestReason: string;
  RequesterId: string;
  ValidFrom: string;
  ValidTo: string;
  VerificationStatus: number;
  VerifierId: string;
  AccessProfile: AccessProfile;
  Id: string;
}

export enum AccessProfileVerificationStatus {
  Pending = 0,
  Granted = 1,
  Refused = 2,
}
