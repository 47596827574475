// InfoModal.tsx
import React from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Translated } from '../UI/Core';
import { UserMapping } from '../../models/Visits/UserMapping';

interface InfoModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  dataUser: UserMapping[] | null;
}

const colors = ['#008000', '#bd9b1a', '#fa8c16', '#ed4f4f', '#6464e5', '#800080', '#1b92bd', '#2f2f2f'];
const bColors = ['#bbe5a69e', '#fff2c3', '#ffecd7', '#ffe1e1', '#e2e2f9', '#f9d5f9', '#e5f5fb', '#efeeee'];

const PacsExternalInfoModal: React.FC<InfoModalProps> = ({ isModalOpen, onClose, dataUser }) => {
  return (
    <Modal
      title={null}
      centered
      open={isModalOpen}
      onCancel={onClose}
      footer={
        <Button onClick={onClose} block>
          Close
        </Button>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <InfoCircleOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
        <h3 style={{ marginTop: '16px', fontWeight: 600, fontSize: '22px' }}>
          <Translated id="visitor.pacs.external.information" />
        </h3>
        <div style={{ marginTop: '16px' }}>
          <Translated id="visitor.pacs.external.information.subtext" />
        </div>
      </div>

      <div style={{ marginTop: '36px' }}>
        {dataUser?.map((item, index) => {
          const color = colors[index % colors.length];
          const bColor = bColors[index % bColors.length];
          return (
            <React.Fragment key={item.ExternalId}>
              <Row>
                <Col xs={11}>
                  <div
                    style={{
                      display: 'inline-block',
                      padding: '4px 16px',
                      color,
                      borderRadius: '4px',
                      backgroundColor: bColor,
                      textAlign: 'center',
                      border: 'solid 1px',
                      width: '80%',
                    }}
                  >
                    {item.ServiceConfigurationName}
                  </div>
                </Col>
                <Col xs={12} style={{ whiteSpace: 'nowrap', fontWeight: 700 }}>
                  {item.ExternalId != null && item.ExternalId}
                  {item.ExternalId === '' && (
                    <div style={{ fontWeight: 500 }}>
                      <Translated id="person.externalId.not.exist" />{' '}
                    </div>
                  )}
                </Col>
              </Row>

              {index < dataUser.length - 1 && <hr />}
            </React.Fragment>
          );
        })}
      </div>
    </Modal>
  );
};

export default PacsExternalInfoModal;
