import React from 'react';
import { Avatar, Col, Divider, Form, FormInstance, Input, Select, Typography } from 'antd';
import { AuditOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Moment } from 'moment';

// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from 'rc-picker/lib/interface';

// Models
import { VisitFormValues } from '../../../../models/Visits/VisitFormValues';

// Components
import { Flex } from '../../../UI/Base';
import { Translated } from '../../../UI/Core';
import { FormColumn } from '../../../UI/Form/FormColumn';
import { DateTimePicker } from '../../../UI/RangePicker/DateTimePicker';
import { useQueryData } from '../../../../hooks/App/useQueryData';
import { ApiEndpoints } from '../../../../data/ApiEndpoints';

const { Title } = Typography;
const { Option } = Select;

// Props
export interface VisitFormFieldsProps<T> {
  form: FormInstance<T>;
  initialValues?: VisitFormValues;
}

// Component
export const VisitFormFields = <T extends object>({ form, initialValues }: VisitFormFieldsProps<T>) => {
  // Intl
  const intl = useIntl();

  const { data: visitor } = useQueryData(ApiEndpoints.reservations.detail, ['Reservations', 'Details']);

  // Visit Form
  const renderDetails = () => (
    <>
      <Col>
        <Form.Item
          name="Title"
          label={<Translated id="visits.form.title" />}
          rules={[{ required: true, message: intl.formatMessage({ id: 'visits.form.title.warning' }) }]}
        >
          <Input disabled placeholder={intl.formatMessage({ id: 'visits.form.title.placeholder' })} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item name="Description" label={<Translated id="visits.form.description" />}>
          <Input disabled placeholder={intl.formatMessage({ id: 'visits.form.description.placeholder' })} />
        </Form.Item>
      </Col>
    </>
  );

  const renderTimeframe = () => (
    <Col>
      <Form.Item name="ValidFrom" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="ValidTo" hidden>
        <Input />
      </Form.Item>

      <Form.Item label={<Translated id="visitors.Timeframe" />}>
        <DateTimePicker
          initialValues={[initialValues?.ValidFrom, initialValues?.ValidTo]}
          onValueChange={(values: RangeValue<Moment>) => {
            const [from, to] = values || [];
            form.setFields([
              { name: 'ValidFrom', value: from },
              { name: 'ValidTo', value: to },
              { name: 'Title', value: `Parking Reservation - ${from?.format('L')}` },
            ]);
          }}
        />
      </Form.Item>
    </Col>
  );

  const setIdentifier = (identifierId: string) => {
    const identifier = visitor?.Identifiers?.find((x) => x.Id === identifierId);
    if (!identifier) return;

    const description = form.getFieldValue('Description').split(' - ')[0];

    form.setFields([
      { name: ['Guests', 0, 'Identifiers', 0, 'IdentifierTypeId'], value: identifier.IdentifierType.Id },
      { name: ['Guests', 0, 'Identifiers', 0, 'Value'], value: identifier.Value },
      { name: ['Guests', 0, 'Identifiers', 0, 'Name'], value: identifier.Name },
      { name: 'Description', value: `${description} - ${identifier.Value}` },
    ]);
  };

  const renderIdentifierSelect = () => (
    <Col>
      <Form.Item
        name={['Guests', 0, 'Identifiers', 0, 'IdentifierTypeId']}
        initialValue={visitor?.Identifiers?.at(0)?.IdentifierType.Id}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['Guests', 0, 'Identifiers', 0, 'Value']}
        initialValue={visitor?.Identifiers?.at(0)?.Value}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item name={['Guests', 0, 'Identifiers', 0, 'Name']} initialValue={visitor?.Identifiers?.at(0)?.Name} hidden>
        <Input />
      </Form.Item>

      <Form.Item name={['Guests', 0, 'Identifiers', 0, 'Id']} hidden>
        <Input />
      </Form.Item>
      {!!visitor?.Identifiers?.length && (
        <Form.Item
          name={['Guests', 0, 'Identifiers', 0, 'Id']}
          initialValue={
            visitor?.Identifiers?.find((x) => x.Value === initialValues?.Description?.split('-')?.pop()?.trim())?.Id ??
            visitor?.Identifiers?.at(0)?.Id
          }
          label={<Translated id="reservations.form.licensePlate" />}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'reservations.form.licensePlate' }),
            },
          ]}
        >
          <Select placeholder={<Translated id="reservations.form.licensePlate" />} onChange={setIdentifier}>
            {visitor?.Identifiers?.map((identifier) => (
              <Option key={identifier.Id} value={identifier.Id}>
                {identifier.Value} | {identifier.Name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Col>
  );

  // Render Form
  return (
    <FormColumn>
      <Flex alignItems="center">
        <Avatar className="avatar-border-color" shape="square" icon={<AuditOutlined />} style={{ marginRight: 8 }} />
        <Title level={4} className="gx-mb-0" style={{ fontWeight: 500 }}>
          <Translated id="visits.form.details" />
        </Title>
      </Flex>
      <Divider />
      {renderDetails()}
      {renderTimeframe()}
      {renderIdentifierSelect()}
    </FormColumn>
  );
};
