import React from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router';

// Hooks
import { useVisitorDetail } from '../../hooks/Visitors/useVisitorDetail';

// Components
import { Container } from '../../components/UI/Base';
import { AccessProfiles, FormDrawer, ProfileHeader, VisitorIdentifiers } from '../../components/Visitors';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';
import { VisitsTabs } from '../../components/Visitors/Visits/VisitsTabs/VisitsTabs';
import { ActionsCard } from '../../components/Visitors/ActionsCard';

export const VisitorDetailPage = () => {
  const { id } = useParams();
  const {
    updating,
    getBreadcrumbMenuProps,
    getProfileHeaderProps,
    getVisitorFormDrawerProps,
    getVisitFormDrawerProps,
    getVisitorProps,
    getVisitorVisitsProps,
    getActionsCardProps,
    getFormAccessProfileRequestDrawerProps,
  } = useVisitorDetail({ id });

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <ProfileHeader {...getProfileHeaderProps()} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={14} lg={14} md={14} sm={24} xs={24}>
            <Col>
              <VisitorIdentifiers {...getVisitorProps()} />
            </Col>
            <Col>
              <AccessProfiles {...getVisitorProps()} />
            </Col>
          </Col>

          <Col xl={10} lg={10} md={10} sm={24} xs={24}>
            <Col>
              <VisitsTabs {...getVisitorVisitsProps()} />
            </Col>
            <Col>
              <ActionsCard {...getActionsCardProps()} />
            </Col>
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getVisitorFormDrawerProps()} />
      <FormDrawer {...getVisitFormDrawerProps()} />
      <FormDrawer {...getFormAccessProfileRequestDrawerProps()} />
    </Spinner>
  );
};
