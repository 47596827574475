import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Divider, Form, Input, Space } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import FormItemLabel from 'antd/lib/form/FormItemLabel';

// Redux
import { useAppDispatch } from '../../../hooks/App/useRedux';
import {
  assignIdentifier,
  removeIdentifier,
} from '../../../store/Reservations/ReservationIdentifiers/ReservationIdentifiers.redux';

// Models
import { Identifier } from '../../../models/Identifier';
import { Visitor } from '../../../models/Visits/Visitor';
import { IdentifierType } from '../../../models/IdentifierType';
import { IdentifierFormValues } from '../../../models/IdentifierFormValues';

// Components
import { Translated } from '../../UI/Core';
import { ValidityTag } from '../../UI/Tag/ValidityTag';
import { ColorButton } from '../../UI/Button/ColorButton';

// Components
const { Item } = Form;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const NoBottomMarginFormItem = styled(Item)`
  margin-bottom: 0;
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface VisitorIdentifiersManagementFormProps {
  visitor?: Visitor;
  identifier?: Identifier;
  identifierType: IdentifierType;
  hasVisits: boolean;
  isCheckedIn: boolean;
}

interface ConfirmProps {
  formValues: IdentifierFormValues;
}

// Component
export const VisitorIdentifierForm = ({
  visitor,
  identifier,
  identifierType,
  hasVisits,
  isCheckedIn,
}: VisitorIdentifiersManagementFormProps) => {
  // Form
  const [form] = Form.useForm();

  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();

  // Input Labels
  const displayLabel = isEmpty(identifierType.DisplayLabel) ? 'Name' : identifierType.DisplayLabel;
  const valueLabel = isEmpty(identifierType.ValueLabel) ? 'Value' : identifierType.ValueLabel;

  // Form Values
  const initialValues: IdentifierFormValues = useMemo(
    () => ({
      PersonId: visitor?.Id,
      Identifier: {
        IdentifierId: identifier?.Id,
        IdentifierTypeId: identifierType.Id,
        Name: identifier?.Name,
        Value: identifier?.Value,
        Enabled: identifier?.Active ?? true,
        Validity: identifier?.IdentifierType.Validity,
        ValidityType: identifier?.IdentifierType.ValidityType,
      },
      AccessProfiles: [],
    }),
    [visitor, identifier, identifierType]
  );

  // Callbacks
  const submitForm = useCallback(
    ({ formValues }: ConfirmProps) => {
      if (formValues) {
        dispatch(assignIdentifier({ formValues }));
      }
    },
    [dispatch]
  );

  const submitRemove = useCallback(
    ({ formValues }: ConfirmProps) => {
      if (formValues && visitor) {
        dispatch(removeIdentifier({ formValues }));
      }
    },
    [dispatch, visitor]
  );

  // Effects
  useEffect(() => {
    // Reset fields after updating the identifier
    form.resetFields();
  }, [form, identifier]);

  // Form Rendering
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values: IdentifierFormValues) =>
        visitor && submitForm({ formValues: { ...initialValues, ...values } })
      }
    >
      {/* Hidden Values */}
      <Item name="PersonId" hidden>
        <Input />
      </Item>
      <Item name={['Identifier', 'IdentifierId']} hidden>
        <Input />
      </Item>
      <Item name={['Identifier', 'IdentifierTypeId']} hidden>
        <Input />
      </Item>
      <Item name={['Identifier', 'Enabled']} hidden>
        <Input />
      </Item>

      {/* Form Values */}
      <StyledSpace align="start">
        <NoSidePaddingFormItem
          name={['Identifier', 'Value']}
          label={valueLabel}
          rules={[
            {
              required: true,
              message: intl.formatMessage(
                { id: 'identifiers.form.warnings.value' },
                { label: valueLabel.toLowerCase() }
              ),
            },
          ]}
        >
          <Input />
        </NoSidePaddingFormItem>

        <NoSidePaddingFormItem
          name={['Identifier', 'Name']}
          label={displayLabel}
          rules={[
            {
              required: true,
              message: intl.formatMessage(
                { id: 'identifiers.form.warnings.name' },
                { label: displayLabel.toLowerCase() }
              ),
            },
          ]}
        >
          <Input />
        </NoSidePaddingFormItem>
      </StyledSpace>
      <Divider />

      <Space>
        <FormItemLabel label={<Translated id="identifiers.validity" />} prefixCls="cls" />
        <ValidityTag
          isDefaultForCheckIn={identifierType.DefaultForCheckIn}
          isAssigned={!!identifier}
          hasVisits={hasVisits}
          isCheckedIn={isCheckedIn}
        />
      </Space>
      <Divider />

      {/* Action Buttons */}
      <NoBottomMarginFormItem>
        <Button type="primary" className="gx-mb-0" htmlType="submit">
          <Translated id="form.confirmButton" />
        </Button>

        {identifier && (
          <ColorButton
            color="red"
            className="gx-mb-0"
            onClick={() => visitor && submitRemove({ formValues: initialValues })}
          >
            <Translated id="form.removeButton" />
          </ColorButton>
        )}
      </NoBottomMarginFormItem>
    </Form>
  );
};
