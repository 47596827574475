/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import { AuthorizedUserAccessProfileVerificationStatus } from '../../models/AccessProfileVerification';

// State
interface AccessProfileVerificationState {
  updating: boolean;
  error: boolean;
}

const initialState: AccessProfileVerificationState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@AUTHORIZED_USER_ACCESS_PROFILE_VERIFICATION';

// Redux Actions|Reducers
const accessProfileVerificationSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Execute Action
    executeAccessProfileVerification: (
      state,
      _action: PayloadAction<AuthorizedUserAccessProfileVerificationStatus>
    ) => {
      state.updating = true;
      state.error = false;
    },
    accessProfileVerificationExecuted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    accessProfileVerificationNotExecuted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Execute Action
  executeAccessProfileVerification,
  accessProfileVerificationExecuted,
  accessProfileVerificationNotExecuted,
} = accessProfileVerificationSlice.actions;

// Export Reducer
export default accessProfileVerificationSlice.reducer;
