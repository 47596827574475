import React, { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { Employee } from '../../../models/Employee';

// Hooks
import { useEmployeeAccessProfilesForm } from './useEmployeeAccessProfilesForm';
import { useEmployeeAccessProfilesReduxState } from './useEmployeeAccessProfilesReduxState';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface AccessProfileAuthorizedUserProps {
  id: string;
  accessProfileName: string;
  requestReason: string;
}

interface EmployeeAccessProfilesProps {
  employee: Employee | null;
  fetchEmployee: (params: any) => Promise<void>;
  updatingEmployee: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProfile: React.Dispatch<React.SetStateAction<AccessProfileAuthorizedUserProps | null>>;
}

export const useEmployeeAccessProfiles = ({
  employee,
  fetchEmployee,
  updatingEmployee,
  setOpen,
  setSelectedProfile,
}: EmployeeAccessProfilesProps) => {
  // Redux State
  const { updating, error, showAssignConfirm } = useEmployeeAccessProfilesReduxState();
  const prevUpdating = usePrevious(updating);

  // Data
  const { data: accessProfiles, fetch, pending } = useData(ApiEndpoints.tenant.accessProfiles, null);

  // Submit Form
  const { AccessProfilesManagementForm } = useEmployeeAccessProfilesForm({
    employee,
    accessProfiles,
    showAssignConfirm,
    setOpen,
    setSelectedProfile,
  });

  // Component State
  const loading = pending || !employee;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch();
  }, [fetch]);

  useEffect(() => {
    // Fetch after updating
    if (employee && prevUpdating === true && updating === false && !error) {
      fetchEmployee({ id: employee.Id });
    }
  }, [prevUpdating, updating, error, fetchEmployee, employee]);

  // Return Hook
  return useMemo(
    () => ({
      accessProfiles,
      AccessProfilesManagementForm,
      loading,
      updating: updating || updatingEmployee,
    }),
    [accessProfiles, AccessProfilesManagementForm, loading, updating, updatingEmployee]
  );
};
