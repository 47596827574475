import React, { useCallback, useMemo, useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import styled from 'styled-components';

// Types
import moment from 'moment';
import { Option } from 'antd/lib/mentions';
import { FormOptions, UseFormProps } from '../../../types/Table';

// Models
import { AccessProfileFormValues } from '../../../models/AccessProfileFormValues';
import { PersonType } from '../../../models/enums/PersonType';
// Actions
import { assignAccessProfiles } from '../../../store/Employees/EmployeeAccessProfiles/EmployeeAccessProfiles.redux';
// Hooks
import { useAppDispatch, useAppSelector } from '../../App/useRedux';

// Components
import { Translated } from '../../../components/UI/Core';

import { Employee } from '../../../models/Employee';
import { Spinner } from '../../../components/UI/Spinner/Spinner';

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface AccessProfileAuthorizedUserProps {
  id: string;
  accessProfileName: string;
  requestReason: string;
}

interface EmployeeReduxProps {
  isEditing?: boolean;
  initialValues?: AccessProfileAuthorizedUserProps | null;
  employee?: Employee | null;
}

const durationOptions = [
  { label: '1 Day', value: 'day' },
  { label: '1 Week', value: 'week' },
  { label: '1 Month', value: 'month' },
  { label: '3 Months', value: '3_months' },
  { label: '6 Months', value: '6_months' },
  { label: '1 Year', value: '1_year' },
  { label: '18 Months', value: '18_months' },
  { label: '2 Years', value: '2_years' },
];

// Hook
export const useEmployeeAccessProfileRequestForm = <T extends object>({
  initialValues,
  employee,
}: EmployeeReduxProps = {}) => {
  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ employeeAccessProfiles }) => employeeAccessProfiles?.updating ?? false);
  const error = useAppSelector(({ employeeAccessProfiles }) => employeeAccessProfiles?.error ?? false);
  const [validFrom, setStartDate] = useState(moment().format());
  const [validTo, setEndDate] = useState(moment().add(1, 'year').format());

  // Submit Handling
  const onSubmit = useCallback(
    (accessProfile: any) => {
      if (!employee) return;

      const updatedProfiles = employee?.AccessProfiles.map((authUser) => {
        if (authUser.AccessProfile.Id === accessProfile.Id) {
          return {
            ...authUser,
            DenyReason: authUser.DenyReason,
            RequestReason: accessProfile.Reason,
            RequesterId: authUser.RequesterId,
            ValidFrom: validFrom,
            ValidTo: validTo,
            VerificationStatus: authUser.VerificationStatus,
            VerifierId: authUser.VerifierId,
          };
        }
        return authUser;
      });

      const formValues: AccessProfileFormValues = {
        AccessProfiles: updatedProfiles,
        PersonId: employee?.Id,
        PersonType: PersonType.Employee,
      };

      dispatch(assignAccessProfiles({ formValues, employee }));
    },
    [dispatch, employee, validFrom, validTo]
  );

  const updateEndDate = (duration: any) => {
    let newEndDate;

    switch (duration) {
      case 'day':
        newEndDate = moment();
        break;
      case 'week':
        newEndDate = moment().add(7, 'days');
        break;
      case 'month':
        newEndDate = moment().add(1, 'month');
        break;
      case '3_months':
        newEndDate = moment().add(3, 'months');
        break;
      case '6_months':
        newEndDate = moment().add(6, 'months');
        break;
      case '1_year':
        newEndDate = moment().add(1, 'year');
        break;
      case '18_months':
        newEndDate = moment().add(18, 'months');
        break;
      case '2_years':
        newEndDate = moment().add(2, 'years');
        break;
      default:
        newEndDate = moment();
    }
    setStartDate(moment().format());
    setEndDate(newEndDate.format());
  };

  // Form
  const AccessProfileRequestForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{ Id: initialValues?.id, Duration: '1_year', Reason: initialValues?.requestReason }}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item name="Duration" label={<Translated id="restricted.access.profile.duration" />}>
                <Select placeholder="Select duration" onChange={updateEndDate}>
                  {durationOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="Reason" label={<Translated id="restricted.access.profile.Reason" />}>
                <Input placeholder="Enter reason" />
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, initialValues]
  );
  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'AccessProfiles',
        Form: AccessProfileRequestForm,
        labels: {
          createButton: <Translated id="app.save" />,
          drawerForm: (
            <div style={{ display: 'flex' }}>
              <div>
                <Translated id="restricted.access.profile.edit" />
              </div>
              <div style={{ marginLeft: 10 }}>{initialValues?.accessProfileName}</div>
            </div>
          ),
          submitButton: <Translated id="app.save" />,
        },
      } as FormOptions<T>),
    [AccessProfileRequestForm, initialValues]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
    }),
    [formOptions, updating, error]
  );
};
