import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Divider, Form, Input, Modal, Row, Select, Space, Switch, Tag } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { ExclamationCircleOutlined, IdcardOutlined } from '@ant-design/icons';
import FormItemLabel from 'antd/lib/form/FormItemLabel';

// Redux
import moment from 'moment/moment';
import { useAppDispatch } from '../../../hooks/App/useRedux';
import {
  assignIdentifier,
  removeIdentifier,
  toggleIdentifierStatus,
} from '../../../store/Visitors/VisitorIdentifiers/VisitorIdentifiers.redux';

// Models
import { Identifier } from '../../../models/Identifier';
import { Visitor } from '../../../models/Visits/Visitor';
import { IdentifierType } from '../../../models/IdentifierType';
import { FormAccessProfile, IdentifierFormValues } from '../../../models/IdentifierFormValues';

// Components
import { Translated } from '../../UI/Core';
import { ValidityTag } from '../../UI/Tag/ValidityTag';
import { ColorButton } from '../../UI/Button/ColorButton';
import { AppColors } from '../../../constants/Utils/Colors';

import { StyledTooltip } from '../../UI/Tooltip/StyledTooltip';
import { TimeFrameUnit } from '../../../models/enums/TimeFrameUnit';
import { getLocalDateTimeString } from '../../../constants/Utils/DateTime';

// Components
const { Item } = Form;
const { confirm } = Modal;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const NoBottomMarginFormItem = styled(Item)`
  margin-bottom: 0;
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface VisitorIdentifiersManagementFormProps {
  visitor: Visitor | null;
  identifier: Identifier | null;
  identifierType: IdentifierType;
  hasVisits: boolean;
  isCheckedIn: boolean;
  updating: boolean;
}

interface ConfirmProps {
  formValues: IdentifierFormValues;
}

// Component
export const VisitorIdentifierForm = ({
  visitor,
  identifier,
  identifierType,
  hasVisits,
  isCheckedIn,
  updating,
}: VisitorIdentifiersManagementFormProps) => {
  // Form
  const [form] = Form.useForm();

  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();

  // Input Labels
  const displayLabel = isEmpty(identifierType.DisplayLabel) ? 'Name' : identifierType.DisplayLabel;
  const valueLabel = isEmpty(identifierType.ValueLabel) ? 'Value' : identifierType.ValueLabel;

  const validityValue =
    !!identifier?.Validity && identifier?.IdentifierType?.OverrideValidity
      ? identifier?.Validity
      : identifier?.IdentifierType.Validity;

  const validityType =
    (!identifier?.ValidityType && !identifier?.Validity) || !identifier?.IdentifierType?.OverrideValidity
      ? identifier?.IdentifierType.ValidityType
      : identifier?.ValidityType;

  const identifierValidityEndDate = getLocalDateTimeString(identifier?.ExpirationDate);

  const now = moment();
  const isExpired = identifier ? now.isAfter(identifier?.ExpirationDate) : false;

  // Form Values
  const initialValues: IdentifierFormValues = useMemo(
    () => ({
      PersonId: visitor?.Id,
      Identifier: {
        IdentifierId: identifier?.Id,
        IdentifierTypeId: identifierType.Id,
        Name: identifier?.Name,
        Value: identifier?.Value,
        Enabled: identifier?.Active ?? true,
        Validity: validityValue,
        ValidityType: validityType,
      },
      AccessProfiles:
        visitor?.AccessProfiles.map<FormAccessProfile>((ap) => ({
          InternalId: ap.Id,
          Name: ap.AccessProfile.Name,
          Enabled: ap.AccessProfile.Enabled,
          ValidFrom: ap.ValidFrom,
          ValidTo: ap.ValidTo,
        })) ?? [],
    }),
    [
      visitor?.Id,
      visitor?.AccessProfiles,
      identifier?.Id,
      identifier?.Name,
      identifier?.Value,
      identifier?.Active,
      identifierType.Id,
      validityValue,
      validityType,
    ]
  );

  // Callbacks
  const showAssignConfirm = useCallback(
    ({ formValues }: ConfirmProps) => {
      if (formValues && visitor) {
        confirm({
          icon: <IdcardOutlined />,
          title: intl.formatMessage({ id: 'identifiers.form.confirm.assign' }),
          content: intl.formatMessage({ id: 'identifiers.form.confirm.assignSub' }),
          okText: intl.formatMessage({ id: 'app.yes' }),
          cancelText: intl.formatMessage({ id: 'app.no' }),
          okType: 'primary',
          onOk: () => dispatch(assignIdentifier({ formValues, visitor })),
          onCancel: () => null,
        });
      }
    },
    [dispatch, visitor, intl]
  );

  const showRemoveConfirm = useCallback(
    ({ formValues }: ConfirmProps) => {
      if (formValues && visitor) {
        confirm({
          icon: <IdcardOutlined />,
          title: intl.formatMessage({ id: 'identifiers.form.confirm.remove' }),
          content: intl.formatMessage({ id: 'identifiers.form.confirm.removeSub' }),
          okText: intl.formatMessage({ id: 'app.yes' }),
          cancelText: intl.formatMessage({ id: 'app.no' }),
          okType: 'primary',
          onOk: () => dispatch(removeIdentifier({ formValues, visitor })),
          onCancel: () => null,
        });
      }
    },
    [dispatch, visitor, intl]
  );

  // Effects
  useEffect(() => {
    // Reset fields after updating the identifier
    form.resetFields();
  }, [form, identifier]);

  const tooltipTitle = isExpired ? (
    <div>
      <strong>
        <Translated id="identifier.Validity.expired.tooltip" />
      </strong>
      <br />
      <span style={{ color: 'orange', display: 'flex', alignItems: 'center' }}>
        <ExclamationCircleOutlined style={{ marginRight: 5 }} />
        <Translated id="identifier.Validity.expired.warning" />
      </span>
      <br />
    </div>
  ) : (
    <div>
      <strong>
        <Translated id="identifier.Validity.tooltip.title" />
      </strong>
      <br />
      <span style={{ color: 'orange', display: 'flex', alignItems: 'center' }}>
        <ExclamationCircleOutlined style={{ marginRight: 5 }} />
        <Translated id="identifier.Validity.tooltip.warning" /> {identifierValidityEndDate || 'No date available'}
      </span>
    </div>
  );

  // Form Rendering
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values: IdentifierFormValues) =>
        visitor && showAssignConfirm({ formValues: { ...initialValues, ...values } })
      }
    >
      {/* Hidden Values */}
      <Item name="PersonId" hidden>
        <Input />
      </Item>
      <Item name={['Identifier', 'IdentifierId']} hidden>
        <Input />
      </Item>
      <Item name={['Identifier', 'IdentifierTypeId']} hidden>
        <Input />
      </Item>
      <Item name={['Identifier', 'Enabled']} hidden>
        <Input />
      </Item>

      {/* Form Values */}
      <StyledSpace align="start">
        <NoSidePaddingFormItem
          name={['Identifier', 'Value']}
          label={valueLabel}
          rules={[
            {
              required: true,
              message: intl.formatMessage(
                { id: 'identifiers.form.warnings.value' },
                { label: valueLabel.toLowerCase() }
              ),
            },
          ]}
        >
          <Input />
        </NoSidePaddingFormItem>

        <NoSidePaddingFormItem
          name={['Identifier', 'Name']}
          label={displayLabel}
          rules={[
            {
              required: true,
              message: intl.formatMessage(
                { id: 'identifiers.form.warnings.name' },
                { label: displayLabel.toLowerCase() }
              ),
            },
          ]}
        >
          <Input />
        </NoSidePaddingFormItem>
      </StyledSpace>
      <Divider />

      {(!identifier?.IdentifierType?.HasValidity || !hasVisits || identifier === null) && (
        <>
          <Space>
            <FormItemLabel label={<Translated id="identifiers.validity" />} prefixCls="cls" />
            <ValidityTag
              isDefaultForCheckIn={identifierType?.DefaultForCheckIn}
              isAssigned={identifier !== null} // explicitly check if identifier is not null
              hasVisits={hasVisits}
              isCheckedIn={isCheckedIn}
            />
          </Space>
          <Divider />
        </>
      )}

      {identifier?.IdentifierType.HasValidity && hasVisits && (
        <>
          <Row style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
            <Item>
              <span style={{ marginRight: 10 }}>
                <Translated id="identifiers.validity" />
              </span>
              <StyledTooltip title={tooltipTitle} placement="bottomRight">
                <Tag
                  style={{ textAlign: 'center', minWidth: 150 }}
                  className="gx-mb-0"
                  color={isExpired ? 'red' : 'green'}
                >
                  {isExpired ? 'Expired' : `${validityValue} ${TimeFrameUnit[validityType ?? 0]}`}
                </Tag>
              </StyledTooltip>
            </Item>

            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>
              <Item name={['Identifier', 'Validity']}>
                <Input
                  placeholder="Quantity"
                  type="number"
                  style={{ width: '100px' }}
                  disabled={!identifier?.IdentifierType.OverrideValidity}
                />
              </Item>
              <Item
                name={['Identifier', 'ValidityType']}
                rules={[{ required: true, message: 'Please select a type!' }]}
                style={{ marginLeft: '-8px' }}
              >
                <Select
                  placeholder="Select Type"
                  style={{ width: '150px' }}
                  disabled={!identifier?.IdentifierType.OverrideValidity}
                >
                  <Select.Option value={TimeFrameUnit.Day}>
                    <Translated id="identifier.timeFrameUnit.day" />
                  </Select.Option>
                  <Select.Option value={TimeFrameUnit.Week}>
                    <Translated id="identifier.timeFrameUnit.week" />
                  </Select.Option>
                  <Select.Option value={TimeFrameUnit.Month}>
                    <Translated id="identifier.timeFrameUnit.month" />
                  </Select.Option>
                  <Select.Option value={TimeFrameUnit.Year}>
                    <Translated id="identifier.timeFrameUnit.year" />
                  </Select.Option>
                </Select>
              </Item>
            </div>
          </Row>
          <Divider style={{ marginTop: 0 }} />
        </>
      )}

      {identifier && (
        <>
          <Space>
            <FormItemLabel label={<Translated id="identifiers.form.block" />} prefixCls="cls" />
            <Switch
              style={{ backgroundColor: !identifier.Active ? AppColors.volcano : undefined }}
              loading={updating}
              checked={!identifier.Active}
              onClick={() => visitor && dispatch(toggleIdentifierStatus({ formValues: initialValues, visitor }))}
              checkedChildren={<Translated id="identifiers.form.blocked" />}
              unCheckedChildren={<Translated id="app.no" />}
            />
          </Space>
          <Divider />
        </>
      )}

      {/* Action Buttons */}
      <NoBottomMarginFormItem>
        <Button type="primary" className="gx-mb-0" htmlType="submit">
          <Translated id="form.confirmButton" />
        </Button>

        {identifier && (
          <ColorButton
            color="red"
            className="gx-mb-0"
            onClick={() => visitor && showRemoveConfirm({ formValues: initialValues })}
          >
            <Translated id="form.removeButton" />
          </ColorButton>
        )}
      </NoBottomMarginFormItem>
    </Form>
  );
};
