import React, { useCallback, useMemo } from 'react';

// Hooks
import { useData } from '../App/useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';

// Constants
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns } from '../../constants/Utils/UIFunctions';

// Components
import { Translated } from '../../components/UI/Core';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { accessProfileVerificationTableColumns } from '../../data/Table/AccessProfileVerificationTableColumns';
import { useAppDispatch, useAppSelector } from '../App/useRedux';
import { AuthorizedUserAccessProfileVerificationStatus } from '../../models/AccessProfileVerification';
import { AccessProfileVerificationStatus } from '../../models/AccessProfile';
import { executeAccessProfileVerification } from '../../store/AccessProfileVerification/AccessProfileVerification.redux';

// Hook
export const useAccessProfileVerificationList = <T extends object>(personType: string) => {
  // Data
  const {
    data,
    pageCount,
    totalCount,
    fetch: fetchPage,
    pending,
  } = useData(ApiEndpoints.accessProfileVerification.authorizedUserAccessProfiles, null);

  const fetch = useCallback(
    (args: any) => {
      return fetchPage({
        ...args,
        personType,
      });
    },
    [fetchPage, personType]
  );

  const updating = useAppSelector(({ accessProfileVerification }) => accessProfileVerification?.updating ?? false);
  const dispatch = useAppDispatch();

  const handleAccessProfileStatusUpdate = useCallback(
    (id: any, reason: string, VerificationStatus: AccessProfileVerificationStatus) => {
      const accessProfileStatus: AuthorizedUserAccessProfileVerificationStatus = {
        Id: id,
        VerificationStatus,
        DenyReason: reason,
      };
      dispatch(executeAccessProfileVerification(accessProfileStatus));
    },
    [dispatch]
  );

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.verification.list }), []);
  const getVerificationDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.AccessProfileVerification.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-auth-screen" style={{ marginRight: 8 }} />
          <Translated id="appMenu.verification" />
        </>
      ),
      data: data ?? [],
      columns: getTableColumns(
        accessProfileVerificationTableColumns({ handleAccessProfileStatusUpdate }),
        LocalStorageKeys.AccessProfileVerification.table.hiddenColumns
      ),
      fetchData: fetch,
    }),
    [data, fetch, handleAccessProfileStatusUpdate]
  );
  const getVerificationTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getVerificationCrudProps = useCallback(
    () => ({
      onRowClick: () => null,
    }),
    []
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getVerificationDataProps,
      getVerificationTableProps,
      getVerificationCrudProps,
      data,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getVerificationDataProps,
      getVerificationTableProps,
      getVerificationCrudProps,
      data,
    ]
  );
};
