/* eslint-disable no-nested-ternary */
import React from 'react';
import { Col, Empty, Row, Skeleton, Tabs } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

// Models
import { Employee } from '../../models/Employee';

// Hooks
import { useEmployeeAccessProfiles } from '../../hooks/Employees/EmployeeAccessProfiles/useEmployeeAccessProfiles';

// Components
import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { Translated } from '../UI/Core';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }

  & .ant-tabs-nav {
    height: 58px;
  }

  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }
`;

// Props
interface AccessProfileAuthorizedUserProps {
  id: string;
  accessProfileName: string;
  requestReason: string;
}

interface AccessProfilesProps {
  employee: Employee | null;
  fetchEmployee: (params: any) => Promise<void>;
  updatingEmployee: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProfile: React.Dispatch<React.SetStateAction<AccessProfileAuthorizedUserProps | null>>;
}

// Component
export const AccessProfiles = ({
  employee,
  fetchEmployee,
  updatingEmployee,
  setOpen,
  setSelectedProfile,
}: AccessProfilesProps) => {
  // Identifiers Hook
  const { loading, updating, accessProfiles, AccessProfilesManagementForm } = useEmployeeAccessProfiles({
    employee,
    fetchEmployee,
    updatingEmployee,
    setOpen,
    setSelectedProfile,
  });

  // Render Methods
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ),
    },
  ];

  const renderEmpty = () => [
    {
      key: '0',
      label: <Translated id="accessProfiles.form.tab" />,
      children: (
        <Row>
          <Col xs={24}>
            <Empty
              description={
                <span>
                  <Translated id="accessProfiles.form.empty" />
                </span>
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  const renderForm = () => [
    {
      key: '0',
      label: <Translated id="accessProfiles.form.tab" />,
      children: (
        <Spinner spinning={updating}>
          <AccessProfilesManagementForm />
        </Spinner>
      ),
    },
  ];

  const renderItems = () => {
    if (loading) return renderLoading();
    if (!accessProfiles || isEmpty(accessProfiles)) return renderEmpty();
    return renderForm();
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs items={renderItems()} />
    </StyledWidget>
  );
};
