import React from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import { CheckCircleOutlined, IdcardOutlined, LogoutOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/es/config-provider/SizeContext';

// Models
import { GuestStatus } from '../../models/enums/GuestStatus';

// Redux
import { useAppDispatch } from '../../hooks/App/useRedux';
import { updateVisitStatus } from '../../store/Visits/Visits.redux';

// Components
import { Translated } from '../UI/Core';
import { ColorButton } from '../UI/Button/ColorButton';
import { Visit } from '../../models/Visits/Visit';
import { isInThePast } from '../../constants/Utils/DateTime';
import { Visitor } from '../../models/Visits/Visitor';

// Components
const { confirm } = Modal;

// Props
interface StatusButtonProps {
  visit: Visit;
  visitor: Visitor;
  size?: SizeType;
  onOk?: () => void;
}

// Component
export const StatusButton = ({ visit, visitor, size = 'middle', onOk }: StatusButtonProps) => {
  // Intl
  const intl = useIntl();
  // Redux
  const dispatch = useAppDispatch();

  const visitStatus = visit?.Guests.find((x) => x.Id === visitor.Id)?.Status ?? GuestStatus.Archived;

  // Callbacks
  const showStatusUpdateConfirm = (e: React.MouseEvent<HTMLElement, MouseEvent>, guestStatus: GuestStatus): void => {
    // Avoids clicking through
    e.stopPropagation();

    // Shows confirm modal
    confirm({
      icon: <IdcardOutlined />,
      title: intl.formatMessage({ id: 'visits.form.confirm.updateStatus' }),
      content: intl.formatMessage({ id: 'visits.form.confirm.updateStatusSub' }),
      okText: intl.formatMessage({ id: 'app.yes' }),
      cancelText: intl.formatMessage({ id: 'app.no' }),
      okType: 'primary',
      onOk: () => {
        dispatch(
          updateVisitStatus({
            Id: visit.Id,
            GuestId: visitor.Id,
            GuestStatus: guestStatus,
            HostId: null,
            HostStatus: null,
          })
        );
        if (onOk) onOk();
      },
      onCancel: () => null,
    });
  };

  // Makes sure the button only gets added for active visits
  if (visit == null || isInThePast(visit.ValidTo)) return null;

  // Renders depending on Status
  switch (visitStatus) {
    case GuestStatus.Created:
    case GuestStatus.Accepted:
      return (
        <ColorButton
          color="orange"
          size={size}
          width={120}
          className="gx-mb-0 gx-ml-1"
          icon={<CheckCircleOutlined />}
          onClick={(e) => showStatusUpdateConfirm(e, GuestStatus.CheckedIn)}
        >
          <span className="gx-ml-1" style={{ fontWeight: 600 }}>
            <Translated id="visits.info.checkInButton" />
          </span>
        </ColorButton>
      );
    case GuestStatus.CheckedIn:
      return (
        <ColorButton
          color="green"
          size={size}
          width={120}
          className="gx-mb-0 gx-ml-1"
          icon={<LogoutOutlined />}
          onClick={(e) => showStatusUpdateConfirm(e, GuestStatus.CheckedOut)}
        >
          <span className="gx-ml-1" style={{ fontWeight: 600 }}>
            <Translated id="visits.info.checkOutButton" />
          </span>
        </ColorButton>
      );
    case GuestStatus.CheckedOut:
    case GuestStatus.Declined:
    case GuestStatus.Archived:
    case GuestStatus.Cancelled:
    default:
      return null;
  }
};
