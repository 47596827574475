export function getTimeFrame(fromDate: string, toDate: string): string {
  const from = new Date(fromDate);
  const to = new Date(toDate);

  const diffInMs = to.getTime() - from.getTime();
  const diffInYears = diffInMs / (1000 * 3600 * 24 * 365.25); // Years
  const diffInMonths = diffInMs / (1000 * 3600 * 24 * 30); // Months
  const diffInWeeks = diffInMs / (1000 * 3600 * 24 * 7); // Weeks

  if (diffInYears >= 2) {
    return '2 years';
  }
  if (diffInYears >= 1) {
    return '1 year';
  }
  if (diffInMonths >= 18) {
    return '18 months';
  }
  if (diffInMonths >= 6) {
    return '6 months';
  }
  if (diffInMonths >= 3) {
    return '3 months';
  }
  if (diffInMonths >= 1) {
    return '1 month';
  }
  if (diffInWeeks >= 1) {
    return '1 week';
  }
  return '1 Day';
}
