import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

// Props
import { EditOutlined, LockFilled } from '@ant-design/icons';
import { AssignConfirmProps } from './useVisitorAccessProfilesReduxState';

// Models
import {
  AccessProfile,
  AccessProfileAuthorizedUser,
  AccessProfileVerificationStatus,
} from '../../../models/AccessProfile';
import { Visitor } from '../../../models/Visits/Visitor';

// Components
import { Translated } from '../../../components/UI/Core';
import { MultiSelect, MultiSelectItem } from '../../../components/UI/MultiSelect/MultiSelect';
import { PersonType } from '../../../models/enums/PersonType';
import { useAppDispatch } from '../../App/useRedux';

// Props
interface AccessProfileAuthorizedUserProps {
  id: string;
  accessProfileName: string;
  requestReason: string;
}

interface VisitorAccessProfilesFormProps {
  visitor: Visitor | null;
  accessProfiles: Array<AccessProfile> | null;
  showAssignConfirm: (props: AssignConfirmProps) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProfile: React.Dispatch<React.SetStateAction<AccessProfileAuthorizedUserProps | null>>;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useVisitorAccessProfilesForm = ({
  visitor,
  accessProfiles,
  showAssignConfirm,
  setOpen,
  setSelectedProfile,
}: VisitorAccessProfilesFormProps) => {
  // State
  const [accessProfileIds, setAccessProfileIds] = useState<Array<string>>([]);
  const [matchingAccessProfiles, setMatchingAccessProfiles] = useState<Array<AccessProfileAuthorizedUser>>([]);
  const dispatch = useAppDispatch();

  // Get the Keys (Id) from all Companies of a Location
  useEffect(() => {
    setAccessProfileIds(
      accessProfiles
        ?.filter((accessProfile) =>
          visitor?.AccessProfiles?.some(
            (visitorAccessProfile) => visitorAccessProfile.AccessProfile.Id === accessProfile.Id
          )
        )
        ?.map((x) => x.Id) ?? []
    );
  }, [accessProfiles, visitor]);

  useEffect(() => {
    const updatedProfiles =
      accessProfiles
        ?.filter((accessProfile) => accessProfileIds.includes(accessProfile.Id))
        .map<AccessProfileAuthorizedUser>((profile) => {
          const existingProfile = visitor?.AccessProfiles.find((authUser) => authUser.AccessProfile.Id === profile.Id);

          return {
            Id: profile.Id,
            AccessProfile: profile,
            DenyReason: existingProfile?.DenyReason || '',
            RequestReason: existingProfile?.RequestReason || '',
            RequesterId: existingProfile?.RequesterId || '',
            ValidFrom: existingProfile?.ValidFrom || profile.ValidFrom,
            ValidTo: existingProfile?.ValidTo || profile.ValidTo,
            VerificationStatus: existingProfile?.VerificationStatus || 0,
            VerifierId: existingProfile?.VerifierId || '',
          };
        }) ?? [];

    setMatchingAccessProfiles(updatedProfiles);
  }, [accessProfileIds, accessProfiles, visitor]);

  const getStatus = useCallback(
    (accessProfileId: string) => {
      return matchingAccessProfiles.find((authUser) => authUser.AccessProfile.Id === accessProfileId)
        ?.VerificationStatus;
    },
    [matchingAccessProfiles]
  );

  const getStatusStyles = (status: AccessProfileVerificationStatus) => {
    switch (status) {
      case AccessProfileVerificationStatus.Pending:
        return { buttonText: <Translated id="access.profile.verification.status.pending" />, buttonColor: 'Orange' };
      case AccessProfileVerificationStatus.Granted:
        return { buttonText: <Translated id="access.profile.verification.status.granted" />, buttonColor: '#52c41a' };
      case AccessProfileVerificationStatus.Refused:
        return { buttonText: <Translated id="access.profile.verification.status.refused" />, buttonColor: 'Red' };
      default:
        return { buttonText: '', buttonColor: '#fffff' };
    }
  };

  // Components
  const VisitorAccessProfilesManagementForm = useCallback(() => {
    const dataSource =
      accessProfiles?.map<MultiSelectItem>((accessProfile) => ({
        key: accessProfile.Id,
        title: accessProfile.Name,
        description: '',
        Renderer: (isAvailable: boolean) => {
          const status = getStatus(accessProfile.Id);
          const { buttonText, buttonColor } = getStatusStyles(status ?? 0);
          const selectedAccessProfile = visitor?.AccessProfiles.find(
            (authUser) => authUser.AccessProfile.Id === accessProfile?.Id
          );

          return (
            <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <span style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {accessProfile.Name}
              </span>
              {isAvailable ? (
                accessProfile.RequiresVerification && <LockFilled style={{ marginRight: 8, color: '#0c195e' }} />
              ) : (
                <span>
                  {!selectedAccessProfile ? (
                    <span style={{ color: buttonColor, fontWeight: 'bold' }}>Pending</span>
                  ) : (
                    accessProfile.RequiresVerification && (
                      <Button
                        type="text"
                        size="small"
                        style={{
                          backgroundColor: buttonColor,
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '0',
                          width: '80px',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (status === 0) {
                            setOpen(true);
                            setSelectedProfile({
                              id: accessProfile.Id,
                              accessProfileName: accessProfile.Name,
                              requestReason:
                                visitor?.AccessProfiles?.find(
                                  (authUser) => authUser.AccessProfile.Id === accessProfile.Id
                                )?.RequestReason || '',
                            });
                          }
                        }}
                      >
                        {buttonText}
                        {status === 0 && <EditOutlined />}
                      </Button>
                    )
                  )}
                </span>
              )}
            </span>
          );
        },
      })) ?? [];

    return (
      <>
        <MultiSelect dataSource={dataSource} targetKeys={accessProfileIds} setTargetKeys={setAccessProfileIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            visitor &&
            showAssignConfirm({
              formValues: {
                AccessProfiles: matchingAccessProfiles,
                PersonId: visitor?.Id,
                PersonType: PersonType.Visitor,
              },
              visitor,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [
    accessProfiles,
    accessProfileIds,
    getStatus,
    setOpen,
    setSelectedProfile,
    visitor,
    showAssignConfirm,
    matchingAccessProfiles,
  ]);

  return useMemo(
    () => ({
      AccessProfilesManagementForm: VisitorAccessProfilesManagementForm,
    }),
    [VisitorAccessProfilesManagementForm]
  );
};
