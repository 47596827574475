import React, { useState } from 'react';
import { Transfer } from 'antd';
import { Translated } from '../Core';

// Props
interface MultiSelectProps {
  dataSource: Array<MultiSelectItem>;
  targetKeys: Array<string>;
  setTargetKeys: React.Dispatch<React.SetStateAction<Array<string>>>;
  titles?: React.ReactNode[];
  showSearch?: boolean;
  oneWay?: boolean;
  height?: number;
}

export interface MultiSelectItem {
  key: string;
  title: string;
  description: string;
  Renderer?: (isAvailable: boolean) => React.JSX.Element;
}

// Component
export const MultiSelect = ({
  dataSource,
  targetKeys,
  setTargetKeys,
  titles = [
    <Translated key="source" id="multiSelect.available" />,
    <Translated key="target" id="multiSelect.selected" />,
  ],
  showSearch = true,
  oneWay = false,
  height = 280,
}: MultiSelectProps) => {
  // State
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  // Event Handlers
  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
  };
  const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  // Render
  return (
    <Transfer
      dataSource={dataSource}
      targetKeys={targetKeys}
      selectedKeys={selectedKeys}
      titles={titles}
      showSearch={showSearch}
      filterOption={(search, item) => item.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())}
      oneWay={oneWay}
      render={(item) => {
        const isAvailable = !targetKeys.includes(item.key);
        return item.Renderer ? item.Renderer(isAvailable) : item.title;
      }}
      onChange={onChange}
      onSelectChange={onSelectChange}
      listStyle={{ width: '50%', height }}
    />
  );
};
