// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  accessProfileVerificationExecuted,
  accessProfileVerificationNotExecuted,
} from './AccessProfileVerification.redux';

// Events
const SignalREvents = {
  AccessProfileVerificationStatusUpdated: `AuthorizedUserAccessProfileStatusUpdatedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  AccessProfileVerificationStatusNotUpdated: `AuthorizedUserAccessProfileStatusNotUpdatedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Update
  hubConnection.on(SignalREvents.AccessProfileVerificationStatusUpdated, (msg) => {
    store.dispatch(accessProfileVerificationExecuted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AccessProfileVerificationStatusNotUpdated, (msg) => {
    store.dispatch(accessProfileVerificationNotExecuted({ history, msg }));
  });
};
