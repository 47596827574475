import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { usePrevious } from 'react-use';
import { isEmpty } from 'lodash';

// Hooks
import { useData } from '../App/useData';
import { useVisitorForm } from './useVisitorForm';
import { useFormDrawer } from '../Drawer/useFormDrawer';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { Visit } from '../../models/Visits/Visit';
import { useVisitForm } from './useVisitForm';
import { isInThePast } from '../../constants/Utils/DateTime';
import { useVisitorAccessProfileRequestForm } from './VisitorAccessProfiles/useVisitorAccessProfileRequestForm';

// Props
interface VisitorDetailProps {
  id?: string;
}

interface AccessProfileAuthorizedUserProps {
  id: string;
  accessProfileName: string;
  requestReason: string;
}

// Hook
export const useVisitorDetail = <T extends object>({ id }: VisitorDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // State
  const [visit, setVisit] = useState<Visit>();
  const [selectedProfile, setSelectedProfile] = useState<AccessProfileAuthorizedUserProps | null>(null);

  // Data
  const { data: visitor, fetch, pending } = useData(ApiEndpoints.visitors.detail, null);
  const prevPending = usePrevious(pending);
  const futureVisits = visitor?.Visits.filter((x) => !isInThePast(x.ValidTo));
  const hasVisits = !isEmpty(futureVisits);
  const { data: actions, fetch: fetchActions, pending: pendingAction } = useData(ApiEndpoints.Actions.list, null);

  // Form Hooks
  const {
    formOptions: visitorFormOptions,
    updating: visitorUpdating,
    error: visitorError,
    showDeleteConfirm,
  } = useVisitorForm<T>({ isEditing: true, visitor });

  const {
    formOptions: visitFormOptions,
    updating: visitUpdating,
    error: visitError,
  } = useVisitForm<T>({ isEditing: true, visit });

  const {
    formOptions: accessProfileRequestFormOptions,
    updating: accessProfileRequestUpdating,
    error: accessProfileRequestError,
  } = useVisitorAccessProfileRequestForm<T>({
    isEditing: true,
    initialValues: selectedProfile,
    visitor,
  });

  // Form Drawer(s)
  const { getFormDrawerProps: getVisitorFormDrawerProps } = useFormDrawer<T>({
    formOptions: visitorFormOptions,
    updating: visitorUpdating,
    error: visitorError,
  });

  const { getFormDrawerProps: getVisitFormDrawerProps } = useFormDrawer<T>({
    formOptions: visitFormOptions,
    updating: visitUpdating,
    error: visitError,
    size: 'large',
  });

  const { getFormDrawerProps: getFormAccessProfileRequestDrawerProps } = useFormDrawer<T>({
    formOptions: accessProfileRequestFormOptions,
    updating: accessProfileRequestUpdating,
    error: accessProfileRequestError,
  });

  // Form Status
  const error = visitorError || visitError;
  const updating = visitorUpdating || visitUpdating;
  const prevUpdating = usePrevious(updating);
  const prevAccessProfileUpdating = usePrevious(accessProfileRequestUpdating);

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.visitors.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      visitor,
      pending,
      setOpen: getVisitorFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [visitor, pending, getVisitorFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      visitor,
      pending,
    }),
    [visitor, pending]
  );
  const getVisitorProps = useCallback(
    () => ({
      visitor,
      fetchVisitor: fetch,
      updatingVisitor: pending,
      hasVisits,
      setOpen: getFormAccessProfileRequestDrawerProps().setOpen,
      setSelectedProfile,
    }),
    [visitor, fetch, pending, hasVisits, getFormAccessProfileRequestDrawerProps]
  );
  const getVisitorVisitsProps = useCallback(
    () => ({
      visitor,
      showVisitDetails: (newVisit: Visit) => {
        setVisit(newVisit);
        getVisitFormDrawerProps().setOpen(true);
      },
    }),
    [visitor, getVisitFormDrawerProps]
  );

  const getActionsCardProps = useCallback(
    () => ({
      actionsData: actions ?? [],
      pendingAction,
      visitor,
      fetchVisitor: fetch,
    }),
    [actions, pendingAction, visitor, fetch]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
    fetchActions();
  }, [fetch, id, fetchActions]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && pending === false && !visitor) {
      notificationService.showError('visitors.notFound');
      navigate('/Visitors');
    }
  }, [prevPending, pending, visitor, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (
      (prevUpdating === true && !updating && !error) ||
      (prevAccessProfileUpdating === true && !accessProfileRequestUpdating && !accessProfileRequestError)
    ) {
      fetch({ id });
    }
  }, [
    prevUpdating,
    updating,
    error,
    fetch,
    id,
    prevAccessProfileUpdating,
    accessProfileRequestUpdating,
    accessProfileRequestError,
  ]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getVisitorFormDrawerProps,
      getVisitFormDrawerProps,
      getVisitorProps,
      getVisitorVisitsProps,
      getActionsCardProps,
      getFormAccessProfileRequestDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getVisitorFormDrawerProps,
      getVisitFormDrawerProps,
      getVisitorProps,
      getVisitorVisitsProps,
      getActionsCardProps,
      getFormAccessProfileRequestDrawerProps,
    ]
  );
};
