import React from 'react';
import { Tag } from 'antd';

// Models
import { GuestStatus } from '../../../models/enums/GuestStatus';
import { Visit } from '../../../models/Visits/Visit';
import { Translated } from '../../UI/Core';
import { isInThePast } from '../../../constants/Utils/DateTime';
import { Visitor } from '../../../models/Visits/Visitor';

// Props
interface StatusCellProps {
  visit?: Visit;
  visitor?: Visitor;
}

// Component
export const StatusCell = ({ visit, visitor }: StatusCellProps) => {
  // Helper Methods
  const getStatusColor = () => {
    const guest = visit?.Guests.find((x) => x.Id === visitor?.Id);

    switch (guest?.Status) {
      case GuestStatus.Created:
        if (visit && isInThePast(visit?.ValidTo)) return undefined;
        return 'cyan';
      case GuestStatus.CheckedIn:
        return 'orange';
      case GuestStatus.CheckedOut:
        return 'green';
      case GuestStatus.Accepted:
        if (visit && isInThePast(visit?.ValidTo)) return undefined;
        return 'purple';
      case GuestStatus.Declined:
      case GuestStatus.Cancelled:
        return 'default';
      default:
        return undefined;
    }
  };
  const getStatusLabel = () => {
    const guest = visit?.Guests.find((x) => x.Id === visitor?.Id);

    if (guest === undefined) return <Translated id="visits.info.noVisits" />;

    switch (guest?.Status) {
      case GuestStatus.Created:
        if (visit && isInThePast(visit?.ValidTo)) return <Translated id="visits.info.expiredTitle" />;
        return <Translated id="visits.info.createdTitle" />;
      case GuestStatus.CheckedIn:
        return <Translated id="visits.info.checkedInTitle" />;
      case GuestStatus.CheckedOut:
        return <Translated id="visits.info.checkedOutTitle" />;
      case GuestStatus.Accepted:
        if (visit && isInThePast(visit?.ValidTo)) return <Translated id="visits.info.expiredTitle" />;
        return <Translated id="visits.info.acceptedTitle" />;
      case GuestStatus.Declined:
        return <Translated id="visits.info.declinedTitle" />;
      case GuestStatus.Cancelled:
        return <Translated id="visits.info.cancelledTitle" />;
      default:
        return undefined;
    }
  };

  return (
    <Tag style={{ textAlign: 'center', width: 120 }} color={getStatusColor()}>
      {getStatusLabel()}
    </Tag>
  );
};
