import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import {
  accessProfileVerificationExecuted,
  accessProfileVerificationNotExecuted,
  executeAccessProfileVerification,
} from './AccessProfileVerification.redux';
import { AuthorizedUserAccessProfileVerificationStatus } from '../../models/AccessProfileVerification';

// Redux

// **************************************************
// ********************* Execute Actions  *********************

// Worker Sagas
function* executeAccessProfileVerificationSaga() {
  yield takeEvery(executeAccessProfileVerification.type, accessProfileVerificationExecuteRequest);
}

function* accessProfileVerificationExecutedSaga() {
  yield takeLatest(accessProfileVerificationExecuted.type, accessProfileVerificationExecuteResponse);
}

function* accessProfileVerificationNotExecutedSaga() {
  yield takeLatest(accessProfileVerificationNotExecuted.type, accessProfileVerificationExecuteError);
}

// Request
function* accessProfileVerificationExecuteRequest(
  action: PayloadAction<AuthorizedUserAccessProfileVerificationStatus>
) {
  try {
    const { payload: updateAccessProfileStatus } = action;
    yield apiService.execute({
      url: `AuthorizedUserAccessProfiles/${updateAccessProfileStatus.Id}/UpdateStatus`,
      method: ApiRequestType.POST,
      data: updateAccessProfileStatus,
    });
  } catch ({ message }) {
    yield put({ type: accessProfileVerificationNotExecuted.type, payload: { msg: { message } } });
  }
}

// Response
function accessProfileVerificationExecuteResponse() {
  notificationService.showSuccess('access.profile.verified.execute');
}

// Error
function accessProfileVerificationExecuteError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('access.profile.verified.executeFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Execute Actions
    executeAccessProfileVerificationSaga(),
    accessProfileVerificationExecutedSaga(),
    accessProfileVerificationNotExecutedSaga(),
  ]);
}
