import React, { useMemo } from 'react';

// Constants
import { MenuProps } from 'antd';
import { AppMenuItem } from '../../components/App/AppMenu/AppMenuItem';
import { AuthIdentityRoles } from '../../constants/Settings/AuthSettings';
import { useAppSelector } from '../../hooks/App/useRedux';
import { VerificationAppMenuItem } from '../../components/App/AppMenu/VerificationAppMenu';

interface AuthenticatedMenuItem {
  key: string;
  label: React.JSX.Element;
  className?: string;
  allowedRoles: string[];
  children?: AuthenticatedMenuSubItem[];
}

interface AuthenticatedMenuSubItem {
  key: string;
  label: React.JSX.Element;
  allowedRoles: string[];
}

const authenticatedMenuItems: AuthenticatedMenuItem[] = [
  {
    key: 'Visitors',
    label: <AppMenuItem id="visitors" to="/Visitors" icon="avatar" />,
    className: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
    allowedRoles: [AuthIdentityRoles.ApplicationUser],
  },
  {
    key: 'Employees',
    label: <AppMenuItem id="employees" to="/Employees" icon="auth-screen" />,
    className: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
    allowedRoles: [AuthIdentityRoles.ApplicationUser],
  },
  {
    key: 'Verification',
    label: <VerificationAppMenuItem id="verification" to="/Verification" icon="wysiwyg" />,
    className: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
    allowedRoles: [AuthIdentityRoles.VerificationManager],
  },
  {
    key: 'Reservations',
    label: <AppMenuItem id="reservations" to="/Reservations" icon="map-styled" />,
    className: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
    allowedRoles: [AuthIdentityRoles.Reservations],
  },
];

export const useMenuItems = () => {
  const userRoles = useAppSelector(({ account }) => account?.userRoles ?? []);

  return useMemo(
    () =>
      authenticatedMenuItems
        .filter((item) => userRoles.some((role) => item.allowedRoles.includes(role)))
        .map(({ allowedRoles, children, ...item }) => ({
          ...item,
          children: children
            ?.filter((child) => userRoles.some((role) => child.allowedRoles.includes(role)))
            .map(({ allowedRoles: _, ...child }) => child),
        })) as MenuProps['items'],
    [userRoles]
  );
};
