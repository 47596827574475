export const LocalStorageKeys = {
  AppSettings: {
    locale: 'AppSettings.locale',
  },
  Employees: {
    table: {
      hiddenColumns: 'Employees.table.hiddenColumns',
    },
  },
  Visitors: {
    table: {
      hiddenColumns: 'Visitors.table.hiddenColumns',
    },
  },
  AccessProfileVerification: {
    table: {
      hiddenColumns: 'AccessProfileVerification.table.hiddenColumns',
    },
  },
};

export const getFromLocalStorage = <T extends object>(key: string, initialValue?: T) => {
  try {
    // Gets value from LocalStorage
    const value = localStorage.getItem(key);
    // Checks if the key/value pair exists
    if (value === null) {
      // If an initial value is provided, it is persisted and returned
      if (initialValue) {
        saveToLocalStorage(key, initialValue);
        return initialValue;
      }
      // Returns undefined in case it does not exists and no initial value is provided
      return undefined;
    }
    // Returns the value parsed into the provided type
    return JSON.parse(value) as T;
  } catch {
    // Returns undefined in case of an error
    return undefined;
  }
};

export const saveToLocalStorage = <T extends object>(key: string, value: T) => {
  try {
    // Serializes and stores the provided value
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch {
    // Error
  }
};
