import React, { useCallback, useMemo } from 'react';
import { Button, Divider, Form, Input, Row, Select, Space, Switch, Tag } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';

// Props
import FormItemLabel from 'antd/lib/form/FormItemLabel';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment'; // Props
import {
  AssignConfirmProps,
  RemoveConfirmProps,
  ToggleIdentifierStatusProps,
} from './useEmployeeIdentifiersReduxState';

// Models
import { Identifier } from '../../../models/Identifier';
import { FormAccessProfile, IdentifierFormValues } from '../../../models/IdentifierFormValues';
import { Employee } from '../../../models/Employee';
import { IdentifierType } from '../../../models/IdentifierType';

// Components
import { Translated } from '../../../components/UI/Core';
import { StyledTooltip } from '../../../components/UI/Tooltip/StyledTooltip';
import { TimeFrameUnit } from '../../../models/enums/TimeFrameUnit';
import { getLocalDateTimeString } from '../../../constants/Utils/DateTime';

// Props
interface EmployeeIdentifiersFormProps {
  updating: boolean;
  employee: Employee | null;
  showAssignConfirm: (props: AssignConfirmProps) => void;
  showRemoveConfirm: (props: RemoveConfirmProps) => void;
  toggleIdentifier: (props: ToggleIdentifierStatusProps) => void;
}

interface EmployeeIdentifiersManagementFormProps {
  identifierType: IdentifierType;
}

// Components
const { Item } = Form;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const NoBottomMarginFormItem = styled(Item)`
  margin-bottom: 0;
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Hook
export const useEmployeeIdentifiersForm = ({
  updating,
  employee,
  showAssignConfirm,
  showRemoveConfirm,
  toggleIdentifier,
}: EmployeeIdentifiersFormProps) => {
  // Intl
  const intl = useIntl();

  // Components
  const EmployeeIdentifiersManagementForm = useCallback(
    ({ identifierType }: EmployeeIdentifiersManagementFormProps) => {
      // Input Labels
      const displayLabel = isEmpty(identifierType.DisplayLabel) ? 'Name' : identifierType.DisplayLabel;
      const valueLabel = isEmpty(identifierType.ValueLabel) ? 'Value' : identifierType.ValueLabel;

      // Employee Identifiers
      const employeeIdentifier = employee?.Identifiers.find(
        (identifier: Identifier) => identifier.IdentifierType.Id === identifierType.Id
      );

      const validityValue =
        !!employeeIdentifier?.Validity && employeeIdentifier?.IdentifierType?.OverrideValidity
          ? employeeIdentifier?.Validity
          : employeeIdentifier?.IdentifierType.Validity;

      const validityType =
        (!employeeIdentifier?.ValidityType && !employeeIdentifier?.Validity) ||
        !employeeIdentifier?.IdentifierType?.OverrideValidity
          ? employeeIdentifier?.IdentifierType.ValidityType
          : employeeIdentifier?.ValidityType;

      const identifierValidityEndDate = getLocalDateTimeString(employeeIdentifier?.ExpirationDate);

      const now = moment();
      const isExpired = !!employeeIdentifier && now.isAfter(employeeIdentifier?.ExpirationDate);

      // Form Values
      const initialValues: IdentifierFormValues = {
        PersonId: employee?.Id,
        Identifier: {
          IdentifierId: employeeIdentifier?.Id,
          IdentifierTypeId: identifierType.Id,
          Name: employeeIdentifier?.Name,
          Value: employeeIdentifier?.Value,
          Enabled: employeeIdentifier?.Active ?? true,
          Validity: validityValue,
          ValidityType: validityType,
        },
        AccessProfiles:
          employee?.AccessProfiles.map<FormAccessProfile>((ap) => ({
            InternalId: ap.AccessProfile.Id,
            Name: ap.AccessProfile.Name,
            Enabled: ap.AccessProfile.Enabled,
            ValidFrom: ap.ValidFrom,
            ValidTo: ap.ValidTo,
          })) ?? [],
      };

      const tooltipTitle = isExpired ? (
        <div>
          <strong>
            <Translated id="identifier.Validity.expired.tooltip" />
          </strong>
          <br />
          <span style={{ color: 'orange', display: 'flex', alignItems: 'center' }}>
            <ExclamationCircleOutlined style={{ marginRight: 5 }} />
            <Translated id="identifier.Validity.expired.warning" />
          </span>
          <br />
        </div>
      ) : (
        <div>
          <strong>
            <Translated id="identifier.Validity.tooltip.title" />
          </strong>
          <br />
          <span style={{ color: 'orange', display: 'flex', alignItems: 'center' }}>
            <ExclamationCircleOutlined style={{ marginRight: 5 }} />
            <Translated id="identifier.Validity.tooltip.warning" /> {identifierValidityEndDate || 'No date available'}
          </span>
        </div>
      );

      return (
        <Form
          layout="vertical"
          initialValues={initialValues}
          onFinish={(values: IdentifierFormValues) =>
            employee && showAssignConfirm({ formValues: { ...initialValues, ...values }, employee })
          }
        >
          {/* Hidden Values */}
          <Item name="PersonId" hidden>
            <Input />
          </Item>
          <Item name={['Identifier', 'IdentifierId']} hidden>
            <Input />
          </Item>
          <Item name={['Identifier', 'IdentifierTypeId']} hidden>
            <Input />
          </Item>
          <Item name={['Identifier', 'Enabled']} hidden>
            <Input />
          </Item>

          {/* Form Values */}
          <StyledSpace align="start">
            <NoSidePaddingFormItem
              name={['Identifier', 'Value']}
              label={valueLabel}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(
                    { id: 'identifiers.form.warnings.value' },
                    { label: valueLabel.toLowerCase() }
                  ),
                },
              ]}
            >
              <Input />
            </NoSidePaddingFormItem>

            <NoSidePaddingFormItem
              name={['Identifier', 'Name']}
              label={displayLabel}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(
                    { id: 'identifiers.form.warnings.name' },
                    { label: displayLabel.toLowerCase() }
                  ),
                },
              ]}
            >
              <Input />
            </NoSidePaddingFormItem>
          </StyledSpace>

          <Divider />
          {employeeIdentifier?.IdentifierType.HasValidity && (
            <>
              <Row style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
                <Item>
                  <span style={{ marginRight: 10 }}>
                    <Translated id="identifiers.validity" />
                  </span>
                  <StyledTooltip title={tooltipTitle} placement="bottomRight">
                    <Tag
                      style={{ textAlign: 'center', minWidth: 150 }}
                      className="gx-mb-0"
                      color={isExpired ? 'red' : 'green'}
                    >
                      {isExpired ? 'Expired' : `${validityValue} ${TimeFrameUnit[validityType ?? 0]}`}
                    </Tag>
                  </StyledTooltip>
                </Item>

                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>
                  <Item name={['Identifier', 'Validity']}>
                    <Input
                      placeholder="Quantity"
                      type="number"
                      style={{ width: '100px' }}
                      disabled={!employeeIdentifier?.IdentifierType.OverrideValidity}
                    />
                  </Item>
                  <Item
                    name={['Identifier', 'ValidityType']}
                    rules={[{ required: true, message: 'Please select a type!' }]}
                    style={{ marginLeft: '-8px' }}
                  >
                    <Select
                      placeholder="Select Type"
                      style={{ width: '150px' }}
                      disabled={!employeeIdentifier?.IdentifierType.OverrideValidity}
                    >
                      <Select.Option value={TimeFrameUnit.Day}>
                        <Translated id="identifier.timeFrameUnit.day" />
                      </Select.Option>
                      <Select.Option value={TimeFrameUnit.Week}>
                        <Translated id="identifier.timeFrameUnit.week" />
                      </Select.Option>
                      <Select.Option value={TimeFrameUnit.Month}>
                        <Translated id="identifier.timeFrameUnit.month" />
                      </Select.Option>
                      <Select.Option value={TimeFrameUnit.Year}>
                        <Translated id="identifier.timeFrameUnit.year" />
                      </Select.Option>
                    </Select>
                  </Item>
                </div>
              </Row>
              <Divider style={{ marginTop: 0 }} />
            </>
          )}
          {employeeIdentifier && (
            <>
              <Space>
                <FormItemLabel label={<Translated id="identifiers.form.status" />} prefixCls="cls" />
                <Switch
                  loading={updating}
                  checked={employeeIdentifier.Active}
                  onClick={() => employee && toggleIdentifier({ formValues: initialValues, employee })}
                  checkedChildren={<Translated id="identifiers.form.active" />}
                  unCheckedChildren={<Translated id="identifiers.form.disabled" />}
                />
              </Space>

              <Divider />
            </>
          )}

          {/* Action Buttons */}
          <NoBottomMarginFormItem>
            <StyledButton type="primary" htmlType="submit">
              <Translated id="form.confirmButton" />
            </StyledButton>

            {employeeIdentifier && (
              <StyledButton
                danger
                onClick={() => employee && showRemoveConfirm({ formValues: initialValues, employee })}
              >
                <Translated id="form.removeButton" />
              </StyledButton>
            )}
          </NoBottomMarginFormItem>
        </Form>
      );
    },
    [employee, intl, updating, showAssignConfirm, toggleIdentifier, showRemoveConfirm]
  );

  return useMemo(
    () => ({
      IdentifierManagementForm: EmployeeIdentifiersManagementForm,
    }),
    [EmployeeIdentifiersManagementForm]
  );
};
