import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// Components
import styled, { keyframes } from 'styled-components';
import { Translated } from '../../UI/Core/Translated';
import { Flex } from '../../UI/Base';
import { useData } from '../../../hooks/App/useData';
import { ApiEndpoints } from '../../../data/ApiEndpoints';
import { PersonType } from '../../../models/enums/PersonType';

// Props
interface AppMenuItemProps {
  id: string;
  to: string;
  icon?: string;
  IconComponent?: any;
}

// Define the animation
const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
`;

// Styled badge with animation
const BadgeCircle = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  text-align: center;
  border-radius: 50%;
  background-color: red;
  margin-left: 8px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ef4444;
    transform: translate(-50%, -50%);
    animation: ${pulse} 1s infinite;
    opacity: 0.6;
  }
`;

export const VerificationAppMenuItem: React.FC<AppMenuItemProps> = ({
  id,
  to,
  icon = undefined,
  IconComponent = undefined,
}) => {
  const { data: authorizedUserAccessProfilesCount, fetch: fetchAuthorizedUserAccessProfilesCount } = useData(
    ApiEndpoints.accessProfileVerification.authorizedUserAccessProfilesCount,
    null
  );

  useEffect(() => {
    fetchAuthorizedUserAccessProfilesCount({ personType: PersonType.Default });
  }, [fetchAuthorizedUserAccessProfilesCount]);

  return (
    <Link to={to}>
      <Flex alignItems="center">
        {IconComponent ? <IconComponent /> : icon && <i className={`icon icon-${icon}`} />}
        <Translated id={`appMenu.${id}`} />
        <div>{!!authorizedUserAccessProfilesCount && <BadgeCircle />}</div>
      </Flex>
    </Link>
  );
};
