import { SagaMiddleware } from 'redux-saga';

// Generic
import GenericSagas from './Generic/Generic.sagas';
// Account
import AccountSagas from './Account/Account.sagas';
// AppSettings
import AppSettingsSagas from './AppSettings/AppSettings.sagas';
// Employees
import EmployeesSagas from './Employees/Employees.sagas';
import EmployeeIdentifiersSagas from './Employees/EmployeeIdentifiers/EmployeeIdentifiers.sagas';
import EmployeeAccessProfilesSagas from './Employees/EmployeeAccessProfiles/EmployeeAccessProfiles.sagas';
import EmployeeImportSagas from './Employees/EmployeeImport/EmployeeImport.sagas';
// Visitors
import VisitorsSagas from './Visitors/Visitors.sagas';
import VisitorIdentifiersSagas from './Visitors/VisitorIdentifiers/VisitorIdentifiers.sagas';
import VisitorAccessProfilesSagas from './Visitors/VisitorAccessProfiles/VisitorAccessProfiles.sagas';
// Visitors
import VisitsSagas from './Visits/Visits.sagas';
// Reservations
import ReservationsSagas from './Reservations/Reservations.sagas';
import ReservationIdentifiersSagas from './Reservations/ReservationIdentifiers/ReservationIdentifiers.sagas';
// Actions
import ActionsSagas from './Actions/Actions.sagas';

import AccessProfileVerificationSagas from './AccessProfileVerification/AccessProfileVerification.sagas';

export const runSagas = (sagaMiddleware: SagaMiddleware<object>) => {
  // Generic
  sagaMiddleware.run(GenericSagas);
  // Account
  sagaMiddleware.run(AccountSagas);
  // AppSettings
  sagaMiddleware.run(AppSettingsSagas);
  // Employees
  sagaMiddleware.run(EmployeesSagas);
  sagaMiddleware.run(EmployeeIdentifiersSagas);
  sagaMiddleware.run(EmployeeAccessProfilesSagas);
  sagaMiddleware.run(EmployeeImportSagas);
  // Visitors
  sagaMiddleware.run(VisitorsSagas);
  sagaMiddleware.run(VisitorIdentifiersSagas);
  sagaMiddleware.run(VisitorAccessProfilesSagas);
  // Visits
  sagaMiddleware.run(VisitsSagas);
  // Reservations
  sagaMiddleware.run(ReservationsSagas);
  sagaMiddleware.run(ReservationIdentifiersSagas);
  // Actions
  sagaMiddleware.run(ActionsSagas);
  sagaMiddleware.run(AccessProfileVerificationSagas);
};
