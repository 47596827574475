import React, { useEffect, useState } from 'react';
import { Badge, Col, Row, Tabs } from 'antd';

// Hooks
import styled from 'styled-components';
import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { useAccessProfileVerificationList } from '../../hooks/AccessProfileVerification/useAccessProfileVerificationList';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';
import { PersonType } from '../../models/enums/PersonType';
import { Translated } from '../../components/UI/Core';
import { useData } from '../../hooks/App/useData';
import { ApiEndpoints } from '../../data/ApiEndpoints';

const { TabPane } = Tabs;

const StyledTabsContainer = styled.div`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: white;
  margin-top: 20px;
  border-radius: 8px;
  padding-top: 8px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 16px;
    margin-left: 10px;
  }
`;

const BadgeCircle = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: red;
  margin-left: 8px;
  font-size: 12px;
  font-weight: normal;
  padding: 1px 6px;
  color: white;
`;

export const AccessProfileVerificationPage = () => {
  const [personType, setPersonType] = useState(PersonType.Visitor.toString());
  const [employeeCount, setEmployeeCount] = useState(0);
  const [visitorCount, setVisitorCount] = useState(0);
  const {
    getBreadcrumbMenuProps,
    getVerificationDataProps,
    getVerificationTableProps,
    getVerificationCrudProps,
    data,
  } = useAccessProfileVerificationList(personType);

  // Data
  const { data: visitorTotalCount, fetch: fetchVisitorAccessProfiles } = useData(
    ApiEndpoints.accessProfileVerification.authorizedUserAccessProfilesCount,
    null
  );
  const { data: employeeTotalCount, fetch: fetchEmployeeAccessProfiles } = useData(
    ApiEndpoints.accessProfileVerification.authorizedUserAccessProfilesCount,
    null
  );

  useEffect(() => {
    fetchVisitorAccessProfiles({ personType: PersonType.Visitor });
    fetchEmployeeAccessProfiles({ personType: PersonType.Employee });
  }, [fetchEmployeeAccessProfiles, fetchVisitorAccessProfiles, personType, data]);

  useEffect(() => {
    setVisitorCount(visitorTotalCount ?? 0);
    setEmployeeCount(employeeTotalCount ?? 0);
  }, [employeeTotalCount, visitorTotalCount]);

  // Tab items
  const tabItems = [
    {
      label: (
        <span>
          <TeamOutlined style={{ marginRight: 8 }} />
          <Translated id="visitor.access.profile.requests.tab.title" />
          <Badge count={visitorCount} style={{ backgroundColor: 'green', marginLeft: '8px' }} />
        </span>
      ),
      key: PersonType.Visitor.toString(),
    },
    {
      label: (
        <span>
          <UserOutlined style={{ marginLeft: 8 }} />
          <Translated id="employee.access.profile.requests.tab.title" />
          <Badge count={employeeCount} style={{ backgroundColor: 'green', marginLeft: '8px' }} />
        </span>
      ),
      key: PersonType.Employee.toString(),
    },
  ];

  return (
    <>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />
      <Container>
        <Row>
          <Col span={24}>
            <StyledTabsContainer>
              <StyledTabs
                activeKey={personType.toString()}
                onChange={(value) => setPersonType(value)}
                items={tabItems}
              />
              <Table {...getVerificationDataProps()} {...getVerificationTableProps()} {...getVerificationCrudProps()} />
            </StyledTabsContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};
