import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { usePrevious } from 'react-use';
import { Store } from 'antd/lib/form/interface';

// Hooks
import { useData } from '../App/useData';
import { useEmployeeRedux } from './useEmployeeRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';

// Components
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { useEmployeeAccessProfileRequestForm } from './EmployeeAccessProfiles/useEmployeeAccessProfileRequestForm';

// Props
interface EmployeeDetailProps {
  id?: string;
}

interface AccessProfileAuthorizedUserProps {
  id: string;
  accessProfileName: string;
  requestReason: string;
}

// Hook
export const useEmployeeDetail = <T extends object>({ id }: EmployeeDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();
  // States
  const [selectedProfile, setSelectedProfile] = useState<AccessProfileAuthorizedUserProps | null>(null);

  // Data
  const { data: employee, fetch, pending } = useData(ApiEndpoints.employees.detail, null);
  const prevPending = usePrevious(pending);

  const { data: actions, fetch: fetchActions, pending: pendingAction } = useData(ApiEndpoints.Actions.list, null);

  // Redux
  const { formOptions, updating, error, showDeleteConfirm } = useEmployeeRedux<T>({
    isEditing: true,
    initialValues: employee as Store,
  });

  const {
    formOptions: accessProfileRequestFormOptions,
    updating: accessProfileRequestUpdating,
    error: accessProfileRequestError,
  } = useEmployeeAccessProfileRequestForm<T>({
    isEditing: true,
    initialValues: selectedProfile,
    employee,
  });

  const prevUpdating = usePrevious(updating);
  const prevAccessProfileUpdating = usePrevious(accessProfileRequestUpdating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  const { getFormDrawerProps: getFormAccessProfileRequestDrawerProps } = useFormDrawer<T>({
    formOptions: accessProfileRequestFormOptions,
    updating: accessProfileRequestUpdating,
    error: accessProfileRequestError,
  });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.employeees.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      employee,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [employee, pending, getFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      employee,
      pending,
    }),
    [employee, pending]
  );

  const getEmployeeProps = useCallback(
    () => ({
      employee,
      fetchEmployee: fetch,
      updatingEmployee: pending,
      setOpen: getFormAccessProfileRequestDrawerProps().setOpen,
      setSelectedProfile,
    }),
    [employee, fetch, getFormAccessProfileRequestDrawerProps, pending, setSelectedProfile]
  );

  const getActionsCardProps = useCallback(
    () => ({
      actionsData: actions ?? [],
      pendingAction,
      employee,
      fetchEmployee: fetch,
    }),
    [actions, pendingAction, employee, fetch]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
    fetchActions();
  }, [fetch, id, fetchActions]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && pending === false && !employee) {
      notificationService.showError('employees.notFound');
      navigate('/Employees');
    }
  }, [prevPending, pending, employee, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (
      (prevUpdating === true && !updating && !error) ||
      (prevAccessProfileUpdating === true && !accessProfileRequestUpdating && !accessProfileRequestError)
    ) {
      fetch({ id });
    }
  }, [
    prevUpdating,
    updating,
    error,
    fetch,
    id,
    prevAccessProfileUpdating,
    accessProfileRequestUpdating,
    accessProfileRequestError,
  ]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getEmployeeProps,
      getActionsCardProps,
      getFormAccessProfileRequestDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getEmployeeProps,
      getActionsCardProps,
      getFormAccessProfileRequestDrawerProps,
    ]
  );
};
