import React, { useState } from 'react';
import { Button } from 'antd';
import { TableColumn } from '../../types/Table';
import { Translated } from '../../components/UI/Core';
import { AuthorizedUserAccessProfile } from '../../models/AccessProfileVerification';
import { AccessProfileVerificationStatus } from '../../models/AccessProfile';
import DenyAccessProfileModal from '../../components/AccessProfileVerification/DenyAccessProfileModal';
import { getTimeFrame } from '../../models/TimeFrame';
import { getLocalDateTimeString } from '../../constants/Utils/DateTime';

interface AccessProfileVerificationTableProps {
  handleAccessProfileStatusUpdate: (
    id: string,
    reason: string,
    VerificationStatus: AccessProfileVerificationStatus
  ) => void;
}

export const accessProfileVerificationTableColumns = ({
  handleAccessProfileStatusUpdate,
}: AccessProfileVerificationTableProps): Array<TableColumn<AuthorizedUserAccessProfile>> => [
  {
    Header: <Translated id="accessProfileVerification.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="accessProfileVerification.name" />,
    id: 'AuthorizedUserName',
    accessor: 'AuthorizedUserName',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="accessProfileVerification.requestedBy" />,
    id: 'RequesterName',
    accessor: 'RequesterName',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="accessProfileVerification.requestCreated" />,
    id: 'RequestCreated',
    accessor: (row) => {
      const { ValidFrom } = row;
      return getLocalDateTimeString(ValidFrom);
    },
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="accessProfileVerification.accessProfile" />,
    id: 'AccessProfile',
    accessor: (row) => {
      return row.AccessProfile.Name;
    },
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="accessProfileVerification.reason" />,
    id: 'RequestReason',
    accessor: 'RequestReason',
  },
  {
    Header: <Translated id="accessProfileVerification.timeFrame" />,
    id: 'ValidFrom',
    accessor: (row) => {
      const { ValidFrom, ValidTo } = row;
      return getTimeFrame(ValidFrom, ValidTo);
    },
  },
  {
    Header: <Translated id="accessProfileVerification.action" />,
    id: 'Action',
    accessor: 'Action',
    width: 170,
    disableSortBy: true,
    disableFilters: true,
    Cell: (value: any) => {
      const {
        row: {
          values: { Id },
        },
      } = value;

      const [isDenyModalOpen, setDenyModalOpen] = useState(false);

      return (
        <>
          <Button
            onClick={() => handleAccessProfileStatusUpdate(Id, '', AccessProfileVerificationStatus.Granted)}
            style={{
              height: '32px',
              marginTop: '6px',
              backgroundColor: '#97df7354',
              color: 'green',
              border: '#97df7354',
            }}
          >
            <span style={{ fontWeight: 600 }}>
              <Translated id="accessProfileVerification.grant" defaultMessage="Open" />
            </span>
          </Button>

          <Button
            onClick={() => setDenyModalOpen(true)}
            style={{
              height: '32px',
              marginTop: '6px',
              backgroundColor: '#ed59594a',
              color: 'red',
              border: '#ed59594a',
            }}
          >
            <span style={{ fontWeight: 600 }}>
              <Translated id="accessProfileVerification.deny" defaultMessage="Open" />
            </span>
          </Button>

          {/* Deny Modal */}
          <DenyAccessProfileModal
            isModalOpen={isDenyModalOpen}
            onClose={() => setDenyModalOpen(false)}
            onDeny={(id, reason) => {
              handleAccessProfileStatusUpdate(id, reason, AccessProfileVerificationStatus.Refused);
              setDenyModalOpen(false);
            }}
            id={Id}
          />
        </>
      );
    },
  },
];
